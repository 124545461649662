import React, { createContext, useContext } from "react"
const ErrorContext = createContext()

export const useError = () => useContext(ErrorContext)

const ErrorProvider = ({ children, rollbar }) => {
    const triggerError = (message, error, orgName) => {
        rollbar.error(error, {
            message: `${message}`,
            orgName
        })
    }
    return (
        <ErrorContext.Provider value={{ triggerError }}>
            {children}
        </ErrorContext.Provider>
    )
}

export default ErrorProvider
