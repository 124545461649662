import { useEffect, useState } from "react"

const CursorHider = ({ children }) => {
    const [hideCursor, setHideCursor] = useState(false)

    useEffect(() => {
        let timeout

        setHideCursor(true)

        const resetCursorTimer = () => {
            setHideCursor(false)
            clearTimeout(timeout)
            timeout = setTimeout(() => setHideCursor(true), 5000)
        }

        document.addEventListener("mousemove", resetCursorTimer)
        document.addEventListener("keydown", resetCursorTimer)

        return () => {
            document.removeEventListener("mousemove", resetCursorTimer)
            document.removeEventListener("keydown", resetCursorTimer)
            clearTimeout(timeout)
        }
    }, [])

    return (
        <div className={hideCursor ? "hide-cursor" : ""}>
            {children}
            <style>{`
        .hide-cursor {
          cursor: none;
        }
      `}</style>
        </div>
    )
}

export default CursorHider
