import React, { useEffect, useState } from "react"
import Head from "next/head"
import theme from "lib/theme"
import { ThemeProvider } from "reakit"
import { RouteGuard } from "components/RouteGuard"
import FeatureFlagsProvider from "lib/FeatureFlags/FeatureFlagsProvider"
import ErrorProvider from "lib/Error/ErrorProvider"
import { SettingsProvider } from "lib/Settings/SettingsProvider"
import Rollbar from "rollbar"
import { clientConfig } from "lib/rollbar"
import { ApolloClientProvider } from "lib/ApolloClient/ApolloClientProvider"
import { SubdomainProvider } from "lib/Subdomain/SubdomainProvider"
import CursorHider from "components/CursorHider/CursorHider"
import StonlyWidget from "components/StonlyWidget"

import "animate.css"

const VisualizeApp = ({ Component, pageProps }) => {
    const [rollbar, setRollbar] = useState(null)

    const [tabName, setTabName] = useState("")

    useEffect(() => {
        if (window !== "undefined") {
            const rollbar = new Rollbar(clientConfig)
            const subdomain = window.location.hostname.split(".")[0]
            setTabName(subdomain === "tv" ? "Wellzesta TV" : "Wellzesta Vis+")
            setRollbar(rollbar)
        }
    }, [])

    useEffect(() => {
        if (rollbar) {
            if (typeof window !== "undefined") {
                const handleError = (message, source, lineno, colno, error) => {
                    rollbar.error(error || message)
                }
                const handleRejection = (event) => {
                    rollbar.error(event.reason)
                }

                window.onerror = handleError
                window.addEventListener("unhandledrejection", handleRejection)

                return () => {
                    window.onerror = null
                    window.removeEventListener(
                        "unhandledrejection",
                        handleRejection
                    )
                }
            }
        }
    }, [rollbar])

    if (rollbar) {
        return (
            <>
                <StonlyWidget />
                <SubdomainProvider>
                    <ErrorProvider rollbar={rollbar}>
                        <SettingsProvider>
                            <Head>
                                <meta
                                    name="viewport"
                                    content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
                                />
                                <title>{tabName}</title>
                            </Head>
                            <ThemeProvider theme={theme}>
                                <ApolloClientProvider>
                                    <FeatureFlagsProvider>
                                        <RouteGuard>
                                            <CursorHider>
                                                <Component {...pageProps} />
                                            </CursorHider>
                                        </RouteGuard>
                                    </FeatureFlagsProvider>
                                </ApolloClientProvider>
                            </ThemeProvider>
                        </SettingsProvider>
                    </ErrorProvider>
                </SubdomainProvider>
            </>
        )
    }

    return null
}

export default VisualizeApp
